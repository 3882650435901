import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, first, map, takeUntil } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/environments/environment';
import { DatabaseService } from 'src/app/servicios/database/database.service';
import { NotificationPlate } from 'src/app/models';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  private wsConnection: WebSocketSubject<string>;
  private isShowingNotification = false;
  private bufferNotifications: any[] = [];
  private translations = {
    aceptar: null,
    InfMatriculaDb: null,
    color: null,
    modelo: null,
    observaciones: null,
    lugar: null,
    enviado: null
  }
  private destroyed$ = new Subject<any>();

  constructor(
    private _databaseService: DatabaseService,
    private _translateService: TranslateService
  ){}

  ngOnDestroy(): void {
    this.closeConnection();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Obtener url de conexion de socket de notificaciones  */
  listenNotification( idMunicipality: number ) {

    if ( !environment.production ) return;

    this._databaseService.getURLConnection( idMunicipality )
    .pipe(
      debounceTime(200),
      first()
    )
    .subscribe({
      next: (response) => {
        this.closeConnection();
        this.setWSConnection( response.url );
      }
    });
      
  }

  /** Establecer conexion */
  setWSConnection( url: string ) {

    this.wsConnection = webSocket({ url: url });
    this.wsConnection
    .pipe(
      map( (data: string) => JSON.parse(data) ),
      takeUntil( this.destroyed$ )
    )
    .subscribe({
      next: (message: NotificationPlate) => {
        this.showNotifications(message);
      },
      error: () => {
      },
    });

  }

  /**
   * Muestra las notificaciones
   * @param notification notificacion
   */
  async showNotifications(notification: NotificationPlate) {

    if ( this.isShowingNotification ) {
      this.bufferNotifications.push(notification);
      return;
    }

    await this.getTranslations();
    this.playSound();

    const { titulo, img, mensaje: { mensaje, lugar, matricula, color, modelo, observaciones }, nivel, origen, fecha  } = notification;

    let config: SweetAlertOptions = {
      customClass: {
        popup: 'animate__animated animate__fadeInDown animate__delay_1s format-notification-plate'
      },
      allowEscapeKey: false,
      allowOutsideClick: false,
      html: `
        <div>

          <h4 class="notification-title">${titulo}</h4>

          <div class="img-container">
            <img src='${img}' alt='matricula' />
          </div>

          <p>${mensaje}</p>

          <div class="info-row">
            <div class="info-item">
              <span>${this.translations.lugar}</span>
              <p>${lugar}</p>
            </div>
          </div>

          <div class='info-row'>
            <div class="info-item">
              <span>${this.translations.InfMatriculaDb}</span>
              <p>${matricula}</p>
            </div>

            <div class="info-item">
              <span>${this.translations.color}</span>
              <p>${color}</p>
            </div>
            
            <div class="info-item">
              <span>${this.translations.modelo}</span>
              <p>${modelo}</p>
            </div>
          </div>

          <div class="info-row">
            <div class="info-item">
              <span>${this.translations.observaciones}</span>
              <p>${observaciones}</p>
            </div>
          </div>

        </div>
      `,
      confirmButtonText: this.translations.aceptar,
      confirmButtonColor: "#294adb",
      footer: `
      <p>${origen}</p>
      <p>${this.translations.enviado} ${moment(fecha).locale(this._translateService.currentLang).fromNow()}</p>
      `,
    };

    this.isShowingNotification = true;

    switch (nivel) {

      case "warn":
        Swal.fire({ ...config }).then( () => this.showNextNotification() );
        this
        break;

      default:
        Swal.fire({
          ...config,
          toast: true,
          position: 'top-right',
          backdrop: undefined,
        }).then( () => this.showNextNotification() );
        break;

    }
  }

  /** Muestra la siguiente notificacion en caso de haber mas de una */
  showNextNotification() {

    this.isShowingNotification = false;
    const nextNotification = this.bufferNotifications.pop();
    if ( nextNotification ) {
      this.showNotifications( nextNotification );
    }
      
  }

  /** Sonido de notificacion */
  playSound() {
    new Audio('/assets/sounds/notification_sound.mp3')
      .play()
      .then( () => null );
  }

   /** Realiza las traducciones cada vez que se invoque */
   getTranslations(): Promise<boolean> {

    return new Promise(resolve => {
      this._translateService.get( ['aceptar', 'InfMatriculaDb', 'color', 'modelo', 'observaciones', 'lugar', 'enviado'] )
      .subscribe({
        next: (translations) => {
          this.translations = translations;
          resolve(true);
        },
        error: () => resolve(false)
      });
    });

  }

  /** Cierra la conexion si existe una instancia */
  closeConnection() {
    if ( this.wsConnection ) {
      this.wsConnection.complete()
      this.wsConnection.error({ code: 1000, reason: 'User changed city' });
    };
  }

}