export interface Guardian {
  idGuardian: number;
  nombre: string;
  idMunicipio: number;
  activo: boolean;
  idCamara1: number;
  idCamara2: number;
  idCamara3: number;
  idCamara4: number;
  tipoCamara1: number;
  tipoCamara2: number;
  tipoCamara3: number;
  tipoCamara4: number;
  megafoniaIdCamara: number;
  megafoniaCamaraSelected: number;
  camara_uno?: string;
  camara_dos?: string;
  camara_tres?: string;
  camara_cuatro?: string;
  camara_megafonia?: string;
  serie_id_camara_uno?: number;
  serie_id_camara_dos?: number;
  serie_id_camara_tres?: number;
  serie_id_camara_cuatro?: number;
}

export enum CamerasGuardian {
  LATERALIZQUIERDA = 1,
  ABAJO = 2,
  LATERALDERECHA = 3,
  PTZ = 4
}