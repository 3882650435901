import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { MessageService } from 'src/app/servicios/message/message.service';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss']
})
export class AudioRecorderComponent {

  private translations = {
    error_acceder_microfono: null
  }
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];

  audioUrl: SafeUrl | null = null;
  isRecording = false;

  constructor(
    private dialogRef: MatDialogRef<AudioRecorderComponent>,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) { 

    // Traducciones
    this._translateService.get(['error_acceder_microfono'])
    .subscribe( translations => this.translations = translations );

  }

  /**
   * Inicia la grabación de audio del usuario, solicitando acceso al
   * micrófono y creando un objeto MediaRecorder.
   *
   * El evento 'dataavailable' se utiliza para recoger los fragmentos de
   * audio grabados y el evento 'stop' para unirlos en un blob y crear una
   * URL que se puede reproducir en un elemento <audio>.
   */
  async startRecording() {

    try {

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      // une los fragmentos de audio en la variable audioChunks
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      // cuando se detiene la grabación, crea el blob y la url del audio
      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        const urlBlob = URL.createObjectURL(audioBlob);
        this.audioUrl = this.sanitizer.bypassSecurityTrustUrl(urlBlob);
        this.audioChunks = [];
      };

      this.mediaRecorder.start();
      this.isRecording = true;
      
    } catch {

      this.dialogRef.close();
      this._messageService.SwalMessage( 'error', this.translations.error_acceder_microfono );

    }

  }

  /**
   * Detiene la grabación de audio y une los fragmentos de audio en un
   * blob. Luego, crea una URL que se puede reproducir en un elemento
   * <audio> y la asigna a la propiedad `audioUrl`..
   */
  stopRecording() {

    if ( this.mediaRecorder ) {
      this.mediaRecorder.stop();
      this.isRecording = false;

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.audioChunks = [];
        const urlBlob = URL.createObjectURL(audioBlob);
        this.audioUrl = this.sanitizer.bypassSecurityTrustUrl(urlBlob);
      }

    }
  }

  /** Cerrar modal */
  closeDialog() {
    this.dialogRef.close();
  }

}
